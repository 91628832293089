.side-drawer{
    height:100%;
    background-color: #fff;
    position: fixed;
    top:0;
    left:0;
    width:75%;
    max-width:400px;
    z-index:1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    }
    .side-drawer.open{
        transform: translateX(0px);
        box-shadow: 2px 0px 20px rgba(0,0,0,0.43);
    }
    .side-drawer ul{
        display:flex;
        flex-direction: column;
        list-style: none;
        justify-content: center;
        height: 100%;
    }
    .side-drawer li{
        margin:10px 0px;
    }
    .side-drawer a{
        font-size:14px;
        font-weight:300;
        color:#2B2B2B;
        font-family: 'Poppins', sans-serif;
        text-decoration: none;
    }
    .side-drawer a:hover,
    .side-drawer a:focus{
        color:#9c9c9c;
    }