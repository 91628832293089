.toggle-button{
    background-color:transparent;
    display:flex;
    flex-direction: column;
    border:none;
    cursor: pointer;
}
.toggle-button:focus{
    outline:none;
}
    