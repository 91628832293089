@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: white !important;
}

h1, h2, h3, h4, h5, h6, p{
  color:#2B2B2B !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  margin-bottom: 0px;
}
img{
  width:auto;
  height:auto;
}
label{
  font-family: 'Poppins', sans-serif;
}
a, a:visited {
  color: #27C492;
  text-decoration: none;
}
a:hover {
  color: #31D19E;
}
.s-bg{
  background-color: #2B2B2B;
  border:1px solid #30363d;
  border-radius: 5px;
}

.p-adjustment{
  font-size:14px !important;
  font-weight:300;
  letter-spacing: 0px;
}

.fs-50{
  font-size:50px !important;
  letter-spacing: -1px;
}
.fw-600{
  font-weight:600;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.h-450{
  height:450px;
}
.classic-img{
  width:100%;
  height: auto;
}
.classic-img-2{
  width:90%;
  height:auto;
}

.span-color{
  color:#27C492;
}
/* Section Paddings */

.sp-90{
  padding:90px 0px;
}
.sb-90{
  padding-bottom:70px;
}
.sp-60{
  padding:60px 0px;
}
.spt-90{
  padding-top:90px;
}
.spt-60{
  padding-top:60px;
}
/* Paddings */
.p-30{
  padding:30px;
}
.p-30-40{
  padding:30px 40px;
}
.p-20{
  padding:20px;
}
.p-15{
  padding: 15px;
}
.p-10{
  padding:10px;
}
.p-20-10{
  padding:20px 10px;
}
.p-20-15{
  padding:20px 15px;
}


.end-footer{
  background-color: #191D28;
}
.end-footer .footer-ul{
  list-style: none;
  margin-bottom: 0px;
}
.end-footer .footer-ul .footer-li{
  margin-bottom:20px;
}
.end-footer .footer-ul .footer-li a{
  color:#fff;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  transition: .3s;
  font-size:14px;
  font-weight: 300;
}
.end-footer .footer-ul .footer-li a:hover{
  opacity:0.5;
}
.end-footer .footer-copyright{
  font-size:12px;
  color:white !important;
  margin-bottom: 100px;
  font-weight: 300;
}

.white-line{
  background-color: white;
  height:1px;
  width:100%;
}

/* Buttons */

.s-btn{
  background-color: #2b2b2b;
  padding: 10px 30px;
  color: #fff !important;
  font-weight: 300 !important;
  text-transform: none !important;
  text-align: center;
  width:max-content;
  transition-duration: 0.3s;
  border: 1px solid #2b2b2b;
  transition-duration: .5s;
  text-decoration: none;
}
.s-btn:hover{
  background-color: white;
  color: #2B2B2B !important;
  border: 1px solid #2B2B2B;
}
.s-btn .get-it-now{
  color: inherit!important;
  font-size: 12px;
  margin-bottom: 0;
  text-align: left;
  font-weight: 300;
}
.s-btn .store-name{
  color: inherit !important;
  font-size: 15px;
  margin-bottom: 0;
  text-align: left;
  font-weight: 300;
}
.s-btn:hover .svg-class{
  fill: #2b2b2b;
}
/* Text Align */

.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.text-left{
  text-align: left;
}

/* Margins */

.mb-0{
  margin-bottom:0px;
}
.mb-30{
  margin-bottom:30px;
}
.mb-25{
  margin-bottom:25px;
}
.mb-20{
  margin-bottom:20px;
}
.mb-15{
  margin-bottom: 15px;
}
.mb-50{
  margin-bottom:50px;
}
.mb-10{
  margin-bottom: 10px;
}
.mt-10{
    margin-top: 10px;
}
.mt-20{
    margin-top: 20px;
}
.mt-30{
    margin-top: 30px;
}
.mt-40{
    margin-top: 40px;
}
.mt-50{
    margin-top: 50px;
}
.mt-60{
    margin-top: 60px;
}
.mt-90{
    margin-top: 90px;
}
.m-30-0{
  margin:30px 0px;
}


.mr-10{
    margin-right: 10px;
}
.ml-10{
    margin-left: 10px;
}
.ml-15{
  margin-left:15px;
}
.ml-20{
  margin-left: 20px;
}
.ml-50{
    margin-left: 50px;
  }


/* paddings */

.pt-10{
    padding-top: 10px;
}
.pt-20{
    padding-top: 20px;
}
.pt-15{
    padding-top: 15px;
}
.pt-30{
    padding-top: 30px;
}
.pt-40{
    padding-top: 40px;
}
.pb-20{
    padding-bottom: 20px;
}
.pb-15{
    padding-bottom: 15px;
}
.pb-40{
    padding-bottom: 40px;
}
.pb-50{
    padding-bottom: 50px;
}

.pt-50{
  padding-top: 50px;
}

.pl-10{
    padding-left:10px
}
.pl-20{
    padding-left:20px
}
.pl-30{
  padding-left:30px
}

.pr-20{
  padding-right: 20px;
}
.pr-30{
  padding-right: 30px;
}
.pr-40{
  padding-right: 40px;
}


/* Headings */
.h_1{
    font-size: 50px;
}
.h-1{
  font-size:40px;
}
.h-2{
  font-size:30px;
}
.h-3{
  font-size:25px;
}
.h-4{
  font-size:20px;
}
.h-5{
  font-size: 18px;
}
.h-6{
  font-size:16px;
}
.paragraph{
  font-size:12px;
  margin-top: 1rem;
  margin-bottom: 1rem !important;
}
.para-10{
    font-size:10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}


/* Font Weights */

.h-bold{
  font-weight:700;
}
.bold-500{
  font-weight:500;
}

b {
  font-weight:600;
}


/* ViewPort Heights */

.height-100{
  height:100vh;
}
.height-90{
  height:90vh;
}
.height-70{
  height:70vh;
}
.height-60{
  height:60vh;
}
.height-50{
  height:50vh;
}
.height-40{
  height:40vh;
}


/* Flex */

.flex{
  display:flex;
}
.flex-align-items{
    display: flex;
    justify-content: center;
}
.flex-center-align{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-end{
  display:flex;
  justify-content: flex-end;
}
.flex-start{
  display:flex;
  justify-content: flex-start;
}
.flex-space-between{
  display:flex;
  justify-content: space-between;
}
.flex-space-evenly{
  display:flex;
  justify-content: space-evenly;
}
.flex-space-around{
  display: flex;
  justify-content: space-around;
}
.flex-row{
  flex-direction: row;
}
.flex-column{
  flex-direction: column;
}
.flex-baseline{
  display:flex;
  align-items: baseline;
}
.flex-simple-center{
  display: flex;
  align-items: center;
}
.flex-justify-center{
  display: flex;
  justify-content: center;
}
.flex-align-start{
  display:flex;
  align-items: flex-start;
}


/* Border Radius */
.br-5{
  border-radius: 5px;
}
.br-8{
  border-radius: 8px;
}
.br-10{
  border-radius: 10px;
}
.br-15{
  border-radius: 15px;
}
.br-20{
  border-radius:20px;
}
.br-25{
  border-radius:25px;
}


/* Width */
.w-100{
  width:100%;
}
.w-90{
  width:90%;
}
.w-80{
  width:80%;
}
.w-70{
  width:70%;
}
.w-60{
  width:60%;
}
.w-50{
  width:50%;
}

/* Input Styles */

.label-class{
  font-size:16px;
  color:#d9d9d9 !important;
}
.form-class{
  padding:15px !important;
  background-color: #0d1117 !important;
  border:1px solid #30363d !important;
  font-size:16px !important;
  color:#d9d9d9 !important;
}
.form-class:focus{
  box-shadow: 0 0 0 .25rem rgba(0,0,0,0.5) !important;
}

/* Sample Divs */

.sample{
  background-color: #0d1117;
  height:auto;
  width:200px;
  border-radius:15px;
  border:1px solid #30363d;
}


/* Extra Css */
/* .banner{
    background-image: url('/images/menu-bg.PNG');
} */


.link-tag {
  margin-top: 30px;
  text-decoration: none !important;
}

.commas{
  font-size: 40px;
}

.test-para{
  color: whitesmoke !important;
}
.testImg{
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.testimonial-slider .slick-arrow.slick-prev,
.testimonial-slider .slick-arrow.slick-next {
  /* background-color: black; */
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
  color: #0d1117;
}
.slick-dots{
 position: initial; 
}
.testimonial-slider .slick-dots li button:before {
  font-size: 15px;
}
.testimonial-slider .slick-dots li {
  top: 15px;
}
.home-banner{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image:url('./home-bg.png');
}
.banner-organization{
  background-repeat: no-repeat;
  background-image:url('./organization-bg.png');
  background-size: auto;
  background-position: center;
}
.banner-business{
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  background-image:url('./business-bg.png'); 
}
.banner-freelancers{
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  background-image:url('./freelancers-bg.png');
}

.tryInvoice{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image:url('./tryinvoice-bg.png');
}
.try-button{
  margin-left: 40px;
}
/* .second-btn{
   margin-left: ;
} */
.box{
    height: 153px;
    width: 260px;
    background-color: #F7F8FE;
}
.benefit-box{
    height: 299px;
    text-align: center;
    border:1px solid #33b493;
}
.l-btn{
    outline:none;
    border:none;
    background-color: white;
    color:#8B7DD5;
    transition: 0.3s;
    height: 60px;
    width: 200px;
    font-family: 'Poppins', sans-serif;
    font-size:16px;
    text-align: center;
    font-weight: 400;
    border: 2px solid #8B7DD5;
  }
  .l-btn:hover{
    background-color: #8B7DD5;
    color: white;
    border: 2px solid #8B7DD5;
  }

  .reverse{
      display: flex; 
      flex-direction: row-reverse ;
  }
  .Testimonial{
      height: auto;
      width: 100%;
  }

  .testimonial-bg{
      background-color: #191D28;
      padding:35px 25px;
      border-radius: 10px;
      margin:0 5px;
  }
  .second-tick{
    margin-left: 30px;
  }
  
  

  .planBox{
      background-color: #F7F8FE;
  }
  .planMonth{
    font-size: 10px;
    opacity: 0.5;
    font-weight: normal;
  }
  .planPara{
      font-size: 10px;
      height:35px;
  }
  .planBox .planBox-tick{
    width:18px;
    height:18px;
  }
  .planBox .planBox-option{
    height:18px;
  }
  .planBox .planBox-option .planBox-text{
    font-weight: 300;
    font-size:10px;
    margin-bottom: 0px;
  }
  .planBox .planBox-btn{
    outline:none;
    background-color: white;
    color:#2B2B2B;
    border:1px solid #8B7DD5;
    transition: 0.3s;
    font-size:12px;
    font-weight: 300;
    padding:20px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
  }
  .planBox .planBox-btn:hover{
    background-color: #8B7DD5;
    color:white;
  }
  .freeTrial{
      background-color: #F6F7FE;
  }
  

.ticks-size{
  width:auto;
  height:100%;
}
.ticks-p{
  font-size:16px;
  font-weight:300;
}
.feature-img{
  height: auto;
  width: 90%;
}
.mobile-only{
  display:none;
}
.question .question-list{
  font-family:'Poppins', sans-serif;
  font-size:14px;
  font-weight:300;
  margin-bottom:15px;
}

.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.2s both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.2s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-8-22 19:7:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@media(min-width:1024px){
  .height-80{
    height:70vh;
  }
  .height-85{
    height: 85vh;
  }
}
@media(max-width:1024px){
  .banner-business{
    background-size: cover;
  }
}
@media (max-width: 1000px) {
  /* .banner-freelancers{
    background-image: none;
    background-size: auto;
    height: auto;
    padding: 0%;
  } */
  /* .mobile-only{
    display:flex;
  } */
  .s-btn{
    padding: 10px 10px;
    width:200px;
    border-radius: 5px;
  }
  .s-btn .get-it-now{
    font-size:9px;
  }
  /* .banner-business{
    background-image: url('./business-bg-m.png');
  } */
}
@media(min-width:768px) and (max-width:1024px){
  .tab-height{
    height:auto !important;
  }
  .tab-img{
    width:80% !important;
  }
  .h-1, .h_1{
    font-size:35px;
  }
  .pt-50{
    padding-top:35px;
  }
  .fs-50{
    font-size:35px !important;
  }
  .tab-flex-end{
    display:flex;
    justify-content: flex-start;
  }
  .footer-ul{
    padding-left:0px;
    margin-top: 30px;;
  }
}
@media (min-width:768px) and (max-width:834px){
  .h-1{
    font-size:30px;
  }
  .tab-height{
    height:auto !important;
  }
  .tab-width{
    width:65% !important;
  }
  .tab-end{
    display:flex;
    justify-content: center;
  }
  .feature-img{
    width:70%;
    margin:20px 0px;
  }
  .h_1, .fs-50{
    font-size:35px !important;
  }
  .tab-center{
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .ticks{
    margin-bottom:20px;
  }
  .home-banner, .banner-freelancers, .banner-business{
    background-position: top;
  }
  .tryInvoice{
    background-image:url('./try-invoice-tab-bg.png');
    background-size: cover;
  }
  .tab-mb-30{
    margin-bottom:30px;
  }
  .banner-organization{
    background-image: none;
  }
  .classic-img{
    width:70%;
  }
  .tab-text-right{
    text-align: right;
  }
}
@media(max-width:480px){
  .mb-50{
    margin-bottom:30px;
  }
  .test-para{
    font-size:12px;
  }
  .sp-90{
    padding:60px 0px;
  }
  .sb-90{
    padding-bottom:40px;
  }
  .special-img{
    width:auto;
    height:100%;
  }
  .height-70{
    height:auto;
  }
  .height-85{
    height:auto;
  }
  .height-80{
    height:80vh;
  }
  .classic-img{
    display: block;
  }
  .sp-60{
    padding:40px 0px;
  }
  .banner-freelancers{
    background-image: none;
  }
  .mobile-only{
    display:flex;
  }
  .flex-initial{
    display: flex;
    align-items: initial;
  }
  /* buttons */
  .s-btn{
    padding: 10px 10px;
    width:200px;
    border-radius: 5px;
  }
  .s-btn .get-it-now{
    font-size:9px;
  }
  .s-btn .store-name{
    font-size:12px;
  }
  .flex-start{
    display:flex;
    justify-content: space-between;
  }
  .flex-end{
    display: flex;
    justify-content: flex-start;
  }
  .display-flex{
    display:block !important;
  }
  .end-footer .footer-ul{
    padding-left:0px !important;
    margin-top:30px;
  }
  .end-footer .footer-ul .footer-li{
    font-weight: 300;
    margin-bottom:10px;
  }
  .end-footer .footer-ul .footer-li a{
    font-size: 14px;
  }
  .end-footer .footer-copyright{
    margin-bottom:7px;
    margin-top:7px;
  }
  /* Margins */

  .res-margin{
    margin-top:30px;
  }
  .res-h-40{
    height:40vh;
  }
  /* Headings */
  .h_1{
    font-size: 25px;
  }
  .h-1{
  font-size:25px;
  }
  .h-2{
  font-size:30px;
  }
  .h-3{
  font-size:20px;
  }
  .h-4{
  font-size:20px;
  }
  .h-5{
  font-size: 18px;
  }
  .h-6{
  font-size:16px;
  }
  .paragraph{
  font-size:14px;
  }
  .para-10{
    font-size:10px;
  }
  .ticks-p{
    font-size: 13px;
  }
  .p-adjustment{
    font-size:12px !important;
  }
  /* .freeTrial{
    height: 575px;
  } */
  .first-tick{
    height: 20px;
  }
  .second-tick{
    padding-left: 10 px;
    height: 20px;
  }
  .Testimonial{
    height: 705px;
  background-color: white;
  }
  .testimonial-slider .slick-arrow.slick-prev,
.testimonial-slider .slick-arrow.slick-next {
  /* background-color: black; */
  width: 20px;
  height: 20px;
}
.slick-prev
    {
        left: 25px !important;
    }
    .slick-next
    {
        right: 25px !important;
    }
  .second-feature{
    padding-left: 10px;
  }
 

  .l-btn{
    outline:none;
    background-color: white;
    color:#8B7DD5;
    transition: 0.3s;
    font-size:12px;
    font-weight: 500;
    height: 50px;
    width: 130px;
    border-radius:5px;
    border: 2px solid #8B7DD5;
  }


  .free{
    
    padding-top: 50px;    
  }
  .freeimg{
    width:100%;
    height:auto;
    
  }
  .feature-img{
    height: auto;
    width: 100%;
    margin-top:20px;
  }
  .fs-50{
    font-size:25px !important;
  }
  .feature-img-business{
    height: auto;
    width: 100%;
  }
  .feature-img-home{
    height: auto;
    width: 100%;
  }
  .feature-h-business{
    padding-top: 30px;
  }
  /* margins */
  .ml-50{
    margin-left: 0px;
  }
  .feature-heading{
    margin-top:20px;
  }
  .m-center{
    text-align: center;
  }
  .extra-padding{
    padding-left:25px;
    padding-right:25px;
  }
  .m-margin{
    padding-top:30px;
  }
  .benefit-box{
    margin-left:30px;
    margin-right:30px;
  }
  .planPara{
    font-size:12px;
  }
  .planBox .planBox-option .planBox-text{
    font-size:11px;
  }
  .planBox .planBox-btn{
    width:80%;
  }
  .home-banner{
    background-position: top;
    background-size: contain;
    background-image:url('./home-bg-m.png');
  }
  .banner-organization{
    background-image: url('./organization-bg-m.png');
    background-position: bottom;
  }
  .banner-business{
    background-image: url('./business-bg-m.png');
  }
  .flex-top{
    display:flex;
    justify-content: flex-start !important;
  }
  .tryInvoice{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image:url('./tryinvoice-bg-m.png');
  }
}
@media(max-width:350px){
  .ticks-p{
    font-size:12px;
  }
  .pr-30{
    padding-right:0px;
  }
  .w-80{
    width:90%;
  }
}